import { FILTER_TYPES } from 'app/components/Table/tableConstants';

export const campaignMetricsTableHeaders = [
  {
    field: 'created_at',
    displayName: 'Time',
    width: '200px'
  },
  {
    field: 'click_id',
    displayName: 'Click ID',
    width: '280px'
  },
  {
    field: 'code',
    displayName: 'Code',
    width: '170px'
  },
  {
    field: 'event_type',
    displayName: 'Event',
    filterType: FILTER_TYPES.SELECT,
    filterOptions: [
      { value: 'scan', label: 'Scan' },
      { value: 'register', label: 'Register' },
      { value: 'reward', label: 'Reward' },
      { value: 'login', label: 'Login' },
      { value: 'filtered', label: 'Filtered' },
      { value: 'shortcode', label: 'Shortcode' },
    ],
    width: '110px'
  },
  {
    field: 'country',
    displayName: 'Country',
    width: '110px'
  },
  {
    field: 'gender',
    displayName: 'Gender',
    width: '100px'
  },
  {
    field: 'age',
    displayName: 'Age',
    width: '80px'
  },
  {
    field: 'variant',
    displayName: 'Variant',
    filterType: FILTER_TYPES.TEXT,
    width: '120px'
  },
  {
    field: 'rewards',
    displayName: 'Rewards',
    width: '450px'
  },
];

export const rowsPerPage = 20;
