
export const partnerTableHeaders = [
  {
    field: '',
    displayName: null,
    width: '60px'
  },
  {
    field: 'name',
    displayName: 'Partner Name',
    width: '330px'
  },
  {
    field: 'support_email',
    displayName: 'Partner Email',
    width: '330px'
  },
  {
    field: 'action',
    displayName: null,
    // width: '330px'
  },
];