import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'js-cookie';
import config from '../../env-config';
import { getOrganisations, getPermissions, setOrganisations, setOrgId, setUserPermissions } from '../../features/auth/authSlice';
import { ORG_ID_COOKIE, ORG_LIST_COOKIE } from '../constants/oauthConstants';
import { RootState } from 'app/store';

export const useAuth = (): [boolean, boolean] => {
  const dispatch = useDispatch();

  const orgId = useSelector<RootState, string | null>((state) => state.auth.orgId);
  const organisations = useSelector<RootState, any>((state) => state.auth.organisations);
  const authPermissions = useSelector<RootState, any>((state) => state.auth.authPermissions);

  useEffect(() => {
    const orgId = cookie.get(ORG_ID_COOKIE);
    const organisationsStringified = cookie.get(ORG_LIST_COOKIE);
    const organisations = organisationsStringified ? JSON.parse(cookie.get(ORG_LIST_COOKIE) || '') : null;

    if (!organisations || organisations.length === 0) {
      dispatch(getOrganisations());
      dispatch(getPermissions());
    } else {
      dispatch(setOrganisations(organisations));
    }
    if (orgId) {
      dispatch(setOrgId(orgId));
    }
  }, []);

  const findCurrentEmployee = authPermissions?.employees?.find((item: any) => item.organisation_id === orgId);
  
  useEffect(() => {
    if (authPermissions) {

      if (findCurrentEmployee) {
        dispatch(setUserPermissions(findCurrentEmployee.permissions))
      } else {
        dispatch(setUserPermissions(null))
      }
    }
  }, [findCurrentEmployee])

  useEffect(() => {
    if (organisations && organisations.length > 0) {
      cookie.set(ORG_LIST_COOKIE, organisations, {
        domain: config.appDomain,
        secure: true,
        sameSite: 'strict',
      });
    }
  }, [organisations]);

  useEffect(() => {
    if (orgId) {
      cookie.set(ORG_ID_COOKIE, orgId, {
        domain: config.appDomain,
        secure: true,
        sameSite: 'strict',
      });
    }
  }, [orgId]);

  return [organisations.length > 0, !!orgId];
};
