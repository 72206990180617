import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forEach } from 'lodash';
import { AppDispatch, RootState } from 'app/store';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { ReactComponent as CheckIcon } from 'static/icons/checkmark.svg';
import { sendShortcodeData, setShortcodeFieldValue } from 'state/campaign/campaignSlice';
import styles from './RewardShortcodeForm.module.scss';
import { Formik } from 'formik';
import classNames from 'classnames';

interface Props {
    closeModal?: (params?: any) => void;
}

export default function RewardShortcodeForm({ closeModal }: Props): React.ReactElement {
    const useAppDispatch = () => useDispatch<AppDispatch>()
    const dispatch = useAppDispatch();

    const shortcodes = useSelector<RootState, any | null>((state) => state.campaign.shortcodes);
    const shortcodeValues = useSelector<RootState, { [key: string]: any }>(
        (state) => state.campaign.shortcodeData.values
    );
    const shortcodeId = useSelector<RootState, string | null>((state) => state.campaign.shortcodeId);
    const [errorMessage, setErrorMessage] = useState({
        name: '',
        notes: ''
    });
    const [submitError, setSubmitError] = useState('');
    const [data, setData] = useState(shortcodeValues);
    const [validation, setValidation] = useState({
        short_code: false,
        redirect_url: false,
    });

    const disabled = !shortcodeId && !!shortcodes.find((item: any) => item.short_code === data.short_code);
    const disabledNotes = data.notes?.length > 5000;

    useEffect(() => {
        if (disabled) {
            setErrorMessage(prev => ({ ...prev, name: 'Shortcode name for this campaign already exists' }));
        } else if (disabledNotes) {
            setErrorMessage(prev => ({ ...prev, notes: 'Notes has too many characters' }));
        } else {
            setErrorMessage({ name: '', notes: '' });
        }
    }, [disabled, disabledNotes])

    const dispatchValueChange = (name: string, value: string | boolean) => {
        dispatch(
            setShortcodeFieldValue({
                field: name,
                value,
            })
        );
    };

    const handleInputChange = (e: any) => {
        const { name, value, checked, type } = e.target;
        setData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }));
        handleValidate(name, value);
    }

    const handleValidate = (name: string, value: string) => {
        if (name === 'short_code') {
            const pattern = new RegExp('^[a-zA-Z_-]*$');
            const check = !pattern.test(value) || value.length > 15;
            setValidation(prev => ({ ...prev, short_code: check }));
        }

        if (name === 'redirect_url') {
            const pattern = new RegExp(
                '^(https?:\\/\\/)' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', // fragment locator
                'i'
            );
            const check = !pattern.test(value);
            setValidation(prev => ({ ...prev, redirect_url: check }))
        }
    }

    const submitData = () => {
        forEach(data, (value: string | boolean, key) => {
            dispatchValueChange(key, value);
        })

        dispatch(sendShortcodeData()).then((data: any) => {
            if (data.error) {
                setSubmitError(data.payload)
            } else {
                closeModal && closeModal();
                setErrorMessage({ name: '', notes: '' });
                setSubmitError('')
            }
        })
    }

    return (
        <Formik
            initialValues={shortcodeValues}
            enableReinitialize={true}
            validate={(values) => {
                const errors: any = {};
                if (!values.short_code) {
                    errors.short_code = 'Shortcode name cannot be empty';
                }
                if (!values.redirect_url) {
                    errors.redirect_url = 'Redirect URL cannot be empty';
                }
                return errors;
            }}
            onSubmit={submitData}
        >
            {({ values, errors, handleChange, handleSubmit, touched }) => (
                <form className={styles.root}
                    onSubmit={handleSubmit}>

                    <div className={styles.formInputContainer}>
                        <label className={styles.inputLabel}>
                            Shortcode <span className={styles.red}>*</span>
                        </label>
                        <Input
                            type="text"
                            className={styles.formInput}
                            value={data.short_code}
                            name="short_code"
                            disabled={!!shortcodeId}
                            onChange={(e: any) => {
                                handleChange(e);
                                handleInputChange(e)
                            }}
                            error={touched.short_code ? (errors.short_code as string) : null}
                        />
                        {validation.short_code && values.short_code?.length && <div className={styles.error}>Please enter a valid shortcode</div>}
                    </div>

                    <div className={styles.formInputContainer}>
                        <label className={styles.inputLabel}>
                            Redirect URL <span className={styles.red}>*</span>
                        </label>
                        <Input
                            type="text"
                            className={styles.formInput}
                            value={data.redirect_url}
                            name="redirect_url"
                            onChange={(e: any) => {
                                handleChange(e);
                                handleInputChange(e)
                            }}
                            error={touched.redirect_url ? (errors.redirect_url as string) : null}
                        />
                        {validation.redirect_url && !!values.redirect_url?.length && <div className={styles.error}>Please enter a valid URL</div>}
                    </div>

                    <div className={styles.formInputContainerInline}>
                        <label className={styles.inputLabel}>
                            Ignore Filter
                        </label>
                        <input
                            type="checkbox"
                            className={classNames({
                                [styles.fakeSwitcher]: true,
                                [styles.fakeSwitcherEnabled]: !!data.ignore_campaign_filter
                            })}
                            checked={!!data.ignore_campaign_filter}
                            name="ignore_campaign_filter"
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>

                    <div className={styles.formInputContainer}>
                        <label className={styles.inputLabel}>
                            Notes
                        </label>
                        <Input
                            multiline
                            className={styles.textarea}
                            value={data.notes}
                            name="notes"
                            onChange={(e: any) => handleInputChange(e)}
                        />
                    </div>

                    {(errorMessage.name || errorMessage.notes) && (
                        <>
                            <div className={styles.error}>{errorMessage.name}</div>
                            <div className={styles.error}>{errorMessage.notes}</div>
                        </>
                    )}
                    {submitError && <div className={styles.error}>{submitError}</div>}

                    <div className={styles.inlineActions}>
                        <Button
                            submit
                            type="action"
                            size="large"
                            disabled={validation.short_code || validation.redirect_url || disabled || disabledNotes}
                            className={styles.submitButton}
                            iconLeft={<CheckIcon height={20} />}
                        >
                            Save
                        </Button>
                    </div>
                </form>
            )}
        </Formik>
    );
}
