import { FILTER_TYPES } from 'app/components/Table/tableConstants';
import { rewardTypes } from 'constants/rewardConstants';

export const rewardsTableHeaders = [
  {
    field: '',
    displayName: null,
    width: '60px'
  },
  {
    field: 'status',
    displayName: 'Status',
    filterType: FILTER_TYPES.SELECT,
    filterOptions: [
      { value: 'active', label: 'Active' },
      { value: 'pending', label: 'Pending' },
      { value: 'expired', label: 'Expired' },
      { value: 'paused', label: 'Paused' },
      { value: 'disabled', label: 'Disabled' },
      { value: 'empty', label: 'Empty' },
    ],
    width: '120px'
  },
  {
    field: 'reward_name',
    displayName: 'Reward Name',
    filterType: FILTER_TYPES.TEXT,
    width: '200px'
  },
  {
    field: 'partner_name',
    displayName: 'Partner',
    filterType: FILTER_TYPES.TEXT,
    width: '200px'
  },
  {
    field: 'reward_value',
    displayName: 'Reward Type',
    filterType: FILTER_TYPES.SELECT,
    filterOptions: rewardTypes,
    width: '150px'
  },
  {
    field: 'reward_probability',
    displayName: 'Win Probability',
    width: '160px'
  },
  {
    field: 'win_count',
    displayName: 'Codes used',
    width: '150px'
  },
  {
    field: 'win_count_today',
    displayName: 'Used today',
    width: '150px'
  },
  {
    field: 'win_max',
    displayName: 'Codes available',
    width: '160px'
  },
  {
    field: 'target_variant',
    displayName: 'Variant',
    filterType: FILTER_TYPES.TEXT,
    width: '170px'
  },
  {
    field: 'target_countries',
    displayName: 'Territories',
    width: '160px'
  },
  {
    field: 'reward_id',
    displayName: 'Reward ID',
    filterType: FILTER_TYPES.TEXT,
    width: '300px'
  },
];

export const rowsPerPage = 20;

export const MAX_DISPLAYED_COUNTRIES = 4;
