import React, { createRef, MutableRefObject, ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ReactComponent as DuplicateIcon } from '../../static/icons/duplicate.svg';
import Modal from '../Modal/Modal';
import { REWARD_STATUSES } from 'app/constants/rewardConstants';
import Tooltip from '../Tooltip/Tooltip';
import classNames from 'classnames';
import styles from './Tabs.module.scss';

interface Props {
    children: ReactElement[] | ReactElement;
    selectedIndex: number;
    tabType?: 'button' | 'default';
    onSelect: (i: number) => void;
    tabsGroup?: TabGroup[];
}

interface TabGroup {
    name: string;
    group: number[];
    rewardStatusLabel?: string;
    enableAction?: (params?: any) => void;
    enableActionLabel?: string;
    action?: (params?: any) => void;
    actionLabel?: string;
    errorLabel?: boolean;
}

const defaultSliderParameters = {
    width: 0,
    left: 0,
};

export default function Tabs({
    children,
    tabType = 'default',
    selectedIndex,
    tabsGroup = [],
    onSelect
}: Props): ReactElement {
    const tabs = React.Children.toArray(children) as ReactElement[];
    const displayedTab: ReactElement = tabs[selectedIndex];
    const [buttonRefs, setButtonRefs]: [MutableRefObject<HTMLButtonElement>[], any] = useState([]);
    const [sliderParameters, setSliderParameters] = useState(defaultSliderParameters);
    const containerRef = useRef<HTMLDivElement>(null);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;


    useEffect(() => {
        setButtonRefs((elRefs: MutableRefObject<HTMLButtonElement>[]) => tabs.map((_, i) => elRefs[i] || createRef()));
    }, [children]);

    useEffect(() => {
        if (tabsGroup.length < 1 && buttonRefs[selectedIndex] && containerRef.current) {
            const currentTabButton = buttonRefs[selectedIndex].current;
            const { width, left } = currentTabButton.getBoundingClientRect();
            const { left: containerLeft } = containerRef.current.getBoundingClientRect();
            setSliderParameters({ width, left: left - containerLeft });
        }
    }, [selectedIndex, buttonRefs]);

    return (
        <div className={classNames(styles.root, tabsGroup.length > 0 ? styles.groupTabs : null)}
            ref={containerRef}>
            {tabsGroup.length > 0 ? (
                <div className={styles.tabGroupHeaders}>
                    {tabsGroup.map((group, index) => (
                        <div key={index} className={styles.tabGroupWrap}>
                            <div className={styles.tabGroupTitleWrap}>
                                <h3>{group.name}</h3>

                                <div className={styles.tabStatus}>
                                    {group.rewardStatusLabel && (
                                        <div
                                            className={
                                                classNames({
                                                    [styles.rewardActive]: group.rewardStatusLabel === REWARD_STATUSES.ACTIVE,
                                                    [styles.rewardExpired]: group.rewardStatusLabel === REWARD_STATUSES.EXPIRED,
                                                    [styles.rewardPending]: group.rewardStatusLabel === REWARD_STATUSES.PENDING,
                                                    [styles.rewardPaused]: group.rewardStatusLabel === REWARD_STATUSES.PAUSED,
                                                    [styles.rewardDisabled]: group.rewardStatusLabel === REWARD_STATUSES.DISABLED,
                                                    [styles.rewardEmpty]: group.rewardStatusLabel === REWARD_STATUSES.EMPTY,
                                                })}
                                        >
                                            {group.rewardStatusLabel}
                                        </div>
                                    )}

                                    {group.enableAction && (
                                        <a>
                                            <Tooltip tooltip={group.enableActionLabel}>
                                                <Modal
                                                    confirmAction={group.enableAction}
                                                    modalTitle="Rewards Details"
                                                    modalContent={`Are you sure you want to ${group.enableActionLabel === 'Enabled' ? 'Disable' : 'Enable'} reward?`}
                                                    confirmLabel={`${group.enableActionLabel === 'Enabled' ? 'Disable' : 'Enable'}`}
                                                    cancelLabel="Cancel"
                                                >
                                                    <span
                                                        className={classNames({
                                                            [styles.fakeSwitcher]: true,
                                                            [styles.fakeSwitcherEnabled]: group.enableActionLabel === 'Enabled',
                                                        })}
                                                    ></span>
                                                </Modal>
                                            </Tooltip>
                                        </a>
                                    )}
                                </div>

                                {group.actionLabel && (
                                    <a>
                                        <Tooltip tooltip={group.actionLabel}>
                                            <Modal
                                                confirmAction={group.action}
                                                modalTitle="Duplicate Rewards Details"
                                                modalContent="Are you sure you want to duplicate the campaign rewards?"
                                                confirmLabel="Duplicate"
                                                cancelLabel="Cancel"
                                            >
                                                <DuplicateIcon height={20} />
                                            </Modal>
                                        </Tooltip>
                                    </a>
                                )}
                            </div>
                            <div className={styles.tabGroupActionWrap}>
                                {group.group.map((tabItem, tabIndex) => (
                                    <Tooltip
                                        key={tabIndex}
                                        tooltip={tabs[tabItem].props.disabled ? tabs[tabItem].props.disabledMessage : null}
                                    >
                                        <button
                                            className={classNames(styles.inline, {
                                                [styles.tabDefault]: tabType === 'default',
                                                [styles.tabButton]: tabType === 'button',
                                                [styles.active]: displayedTab.props.name === tabs[tabItem].props.name,
                                            })}
                                            disabled={tabs[tabItem].props.disabled}
                                            onClick={() => {
                                                if (!isCampaignLoading) {
                                                    onSelect(tabItem)
                                                }
                                            }}
                                            key={tabItem}
                                            ref={buttonRefs[tabItem]}
                                        >
                                            {tabs[tabItem].props.name}
                                            {tabs[tabItem].props.errorLabel && (
                                                <svg width="22" height="22" viewBox="0 0 1024 1024" aria-labelledby="icon-ERROR-title" fill="rgba(255,83,84, 1)">
                                                    <path d="M512.2,71.2c-40.4,0-75.3,20.7-96,54.6L22.3,783.7C1.6,818.6,0.5,861.1,21.2,896c19.6,34.9,55.7,56.7,96,56.7H906c40.4,0,77.5-20.7,97.1-56.7c19.6-34.9,19.6-77.5-1.1-112.4L608.2,125.8C587.5,91.9,552.6,71.2,512.2,71.2z M512.2,136.7c16.4,0,30.5,7.6,39.3,22.9l395,657.9c8.7,14.2,8.7,31.6,1.1,46.9c-8.7,14.2-22.9,22.9-40.4,22.9H117.2c-16.4,0-31.7-8.7-40.4-22.9c-8.7-14.2-7.6-32.7,1.1-46.9l395-657.9C481.6,145.4,495.8,136.7,512.2,136.7z M512.2,351.6c-18.5,0-32.7,14.2-32.7,32.7v229.1c0,18.5,14.2,32.7,32.7,32.7s32.7-14.2,32.7-32.7V384.4C544.9,365.8,530.7,351.6,512.2,351.6z M512.2,692c-18.1,0-32.7,14.7-32.7,32.7c0,18.1,14.7,32.7,32.7,32.7c18.1,0,32.7-14.7,32.7-32.7C544.9,706.7,530.3,692,512.2,692z"></path>
                                                </svg>
                                            )}
                                        </button>
                                    </Tooltip>
                                )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className={styles.tabHeaders}>
                    {tabs.map((tab, i) => (
                        <Tooltip key={i} tooltip={tab.props.disabled ? tab.props.disabledMessage : null}>
                            <button
                                className={classNames({
                                    [styles.tabDefault]: tabType === 'default',
                                    [styles.tabButton]: tabType === 'button',
                                    [styles.active]: displayedTab.props.name === tab.props.name,
                                })}
                                disabled={tab.props.disabled}
                                onClick={() => onSelect(i)}
                                key={i}
                                ref={buttonRefs[i]}
                            >
                                {tab.props.name}
                            </button>
                        </Tooltip>
                    ))}
                    {tabType === 'default' && (
                        <div className={styles.slider}
                            style={{ left: sliderParameters.left, width: sliderParameters.width }}></div>
                    )}
                </div>
            )}

            <div className={styles.tabContent}>{displayedTab}</div>
        </div>
    );
}
