import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'app/store';
import Skeleton from 'app/components/Skeleton/Skeleton';
import { getCampaigns } from '../../state/campaigns/CampaignsSlice';
import CampaignsList from './components/CampaignsList/CampaignsList';
import styles from './CampaignManager.module.scss';
import { fetchToken } from 'features/auth/authApi';

export default function CampaignManager(): React.ReactElement {
  const useAppDispatch = () => useDispatch<AppDispatch>();
  const dispatch = useAppDispatch();

  const [message, setMessage] = useState('');
  const isCampaignsLoading = useSelector<RootState, boolean>((state) => state.campaigns.isCampaignsLoading);

  const handleCampaigns = async () => {
    await fetchToken();
    dispatch(getCampaigns())
      .then((data) => {
        if (!data.payload?.length) {
          setMessage('There are no active campaigns at the moment!')
        }
      })
  }

  useEffect(() => {
    handleCampaigns();
  }, []);

  return (
    <div className={styles.root}>
      <div className={message ? styles.container : styles.campaignsListHolder}>
        <Skeleton isLoading={isCampaignsLoading}>
          {message ?
            <p className={styles.placeholder}>{message}</p>
            : (
              <CampaignsList />
            )}
        </Skeleton>
      </div>
    </div>
  );
}
