import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RootState } from 'app/store';
import CampaignCard from '../CampaignCard/CampaignCard';
import styles from './CampaignsList.module.scss';

export default function CampaignsList(): React.ReactElement {
  const campaigns = useSelector<RootState, any[]>((state) => state.campaigns.campaigns);
  const sortedCampaigns = [...campaigns]?.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

  return (
    <div className={styles.root}>
      {!!campaigns?.length && <h1 className={styles.heading}>Active Campaigns</h1>}
      <div className={styles.campaignsList}>
        {sortedCampaigns.map((campaign) => (
          <CampaignCard key={campaign.campaign_name} campaign={campaign} />
        ))}
      </div>
    </div>
  );
}

CampaignsList.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object),
};
