import React, { useEffect, useState } from 'react';
import { ReactComponent as CrossSmallIcon } from '../../static/icons/cross-small.svg';
import styles from './Modal.module.scss';
import { useDispatch } from 'react-redux';
import { resetShortcodeFormData } from 'state/campaign/campaignSlice';

interface Props {
    children?: React.ReactNode;
    confirmAction?: (params?: any) => void;
    modalTitle?: string;
    modalContent?: string;
    confirmLabel?: string;
    cancelLabel?: string;
    formAction?: boolean;
    closeModal?: (params?: any) => void;
}

export default function Modal({
    children,
    confirmAction,
    confirmLabel = 'confirm',
    cancelLabel = 'cancel',
    modalTitle,
    modalContent,
    formAction,
    closeModal
}: Props): React.ReactElement {
    const dispatch = useDispatch();
    const [openModalState, setOpenModalState] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(resetShortcodeFormData())
        }
    }, [])

    const handleModal = () => {
        setOpenModalState(!openModalState)
    }

    const confirmModalAction = () => {
        if (confirmAction) {
            confirmAction()
        }
        handleModal();
    }

    return (
        <div className={styles.root}>
            {confirmAction ? (
                <>
                    <div className={styles.trigger} onClick={handleModal}>
                        {children}
                    </div>

                    {openModalState && (
                        <div className={styles.modalWrapper}>
                            <div className={styles.modalBody}>
                                <div className={styles.modalContent}>
                                    <h3>{modalTitle}</h3>
                                    <p>{modalContent}</p>
                                </div>
                                <div className={styles.modalAction}>
                                    <button className={styles.confirmButton} onClick={confirmModalAction}>{confirmLabel}</button>
                                    <button className={styles.cancelButton} onClick={handleModal}>{cancelLabel}</button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (formAction &&
                <div className={styles.modalWrapper}>
                    <div className={styles.modalBodyForm}>
                        <div className={styles.modalRow}>
                            <h3>{modalTitle}</h3>
                            <CrossSmallIcon
                                className={styles.closeIcon}
                                onClick={closeModal}
                            />
                        </div>
                        {children}
                    </div>
                </div>
            )
            }
        </div>
    );
}
