import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import _, { pickBy } from 'lodash';
import { RootState } from 'app/store';
import { editCampaign, setError } from '../../../../state/campaigns/CampaignsSlice';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Dropdown from 'components/Dropdown/Dropdown';
import Modal from 'components/Modal/Modal';
import { CAMPAIGN_TYPE, DEFAULT_TIMEZONE, OFFSETS } from 'constants/rewardConstants';
import { CampaignProperties } from 'interfaces/campaignInterfaces';
import styles from './EditCampaign.module.scss';

export default function EditCampaign(): React.ReactElement {
  const dispatch = useDispatch();

  const campaign = useSelector<RootState, any>((state) => state.campaign.campaign);
  const campaignProperties = useSelector<RootState, CampaignProperties>((state) => state.campaign.campaignProperties);
  const error = useSelector<RootState, any>((state) => state.campaigns.error);
  const userPermissions = useSelector<RootState, any>((state) => state.auth.userPermissions);

  const selectedTimezone = campaignProperties?.view_timezone ? campaignProperties?.view_timezone : DEFAULT_TIMEZONE;
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({
    ...campaignProperties,
    view_timezone: selectedTimezone,
    allow_multiple_registration: campaignProperties?.allow_multiple_registration &&
      JSON.parse(campaignProperties?.allow_multiple_registration),
    token_on_register: campaignProperties?.token_on_register &&
      JSON.parse(campaignProperties?.token_on_register),
    single_use_codes: campaignProperties?.single_use_codes &&
      JSON.parse(campaignProperties?.single_use_codes),
    reward_value_groups: campaignProperties?.reward_value_groups &&
      campaignProperties?.reward_value_groups?.join(', ')
  });

  const handleInputChange = (e: any) => {
    const { name, value, checked, type } = e?.target;
    setData(prev => ({
      ...prev, [name]: type === 'checkbox' ? checked : value
    }));
  }

  const formattedData = {
    ...data,
    allow_multiple_registration: JSON.stringify(data?.allow_multiple_registration),
    token_on_register: JSON.stringify(data?.token_on_register),
    single_use_codes: JSON.stringify(data?.single_use_codes),
    reward_value_groups: data?.reward_value_groups?.split(', ')
  }

  const submitData = (values: any) => {
    const newProperties = {
      ...values.properties,
      ...pickBy(formattedData, key => key !== undefined),
    }
    dispatch(editCampaign({ ...values, properties: newProperties }));
    setEdit(false);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={campaign}
      validate={(values) => {
        const errors: any = {};
        if (!values.campaign_name) {
          errors.campaign_name = 'Please, specify campaign name';
        }
        return errors;
      }}
      onSubmit={(values) => submitData(values)}
    >
      {({ values, setFieldValue, setTouched, errors, dirty, handleChange, handleSubmit, touched }) => (
        <form
          className={styles.root}
          onSubmit={handleSubmit}
        >
          <div className={styles.headerSection}>
            <ImageUpload
              source={'campaign_image'}
              imageUrl={values?.campaign_image_url}
              onImageSubmit={(e) => {
                setTouched({ ...touched, campaign_image_url: true }, true);
                setFieldValue('campaign_image_url', e);
                dispatch(setError(''));
              }}
              error={touched.campaign_image_url ? errors.campaign_image_url || error : null}
            />
          </div>

          <div className={styles.settings}>
            <div className={styles.basic}>
              <p className={styles.heading}>Settings</p>
              <div className={styles.formInputContainer}>
                <label className={styles.inputLabel}>Campaign Name</label>
                <Input
                  className={styles.formInput}
                  value={values?.campaign_name}
                  name="campaign_name"
                  onChange={handleChange}
                  error={touched.campaign_name ? (errors.campaign_name as string) : null}
                />
              </div>
              <div className={styles.formInputContainer}>
                <label className={styles.inputLabel}>View Timezone</label>
                <Dropdown
                  options={OFFSETS.map((offset) => ({ value: offset, label: 'UTC' + offset }))}
                  className={styles.formInput}
                  value={{
                    label: 'UTC' + data?.view_timezone,
                    value: data?.view_timezone
                  }}
                  name="startOffset"
                  onChange={({ value }) => setData(prev => ({ ...prev, view_timezone: value }))}
                />
              </div>
            </div>

            {userPermissions?.features?.redemption_extended_settings && (
              <div className={styles.extended}>
                <div className={styles.inlineContainer}>
                  <p className={styles.heading}>Extended settings</p>
                  <Modal
                    confirmAction={() => setEdit(true)}
                    modalTitle={'Extended Settings'}
                    modalContent={'Are you sure you want to modify these settings?'}
                    confirmLabel={'Confirm'}
                    cancelLabel={'Cancel'}
                  >
                    <button
                      type="button"
                      className={styles.actionButton}
                    >
                      Edit
                    </button>
                  </Modal>
                </div>

                {campaignProperties?.campaign_type && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Campaign Type</label>
                    <Dropdown
                      options={CAMPAIGN_TYPE.map(item => item)}
                      className={styles.formInput}
                      disabled={!edit}
                      value={{
                        label: CAMPAIGN_TYPE?.find(item => item.value === data?.campaign_type)?.label,
                        value: data?.campaign_type
                      }}
                      name="campaign_type"
                      onChange={({ value }) => setData(prev => ({ ...prev, campaign_type: value }))}
                    />
                  </div>
                )}

                {(campaignProperties?.sender_name || campaignProperties?.sender_name === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Sender Name</label>
                    <Input
                      className={styles.formInput}
                      value={data?.sender_name}
                      name="sender_name"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {(campaignProperties?.login_url || campaignProperties?.login_url === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Login URL</label>
                    <Input
                      className={styles.formInput}
                      value={data?.login_url}
                      name="login_url"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {(campaignProperties?.error_redirect_url || campaignProperties?.error_redirect_url === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Error Redirect URL</label>
                    <Input
                      className={styles.formInput}
                      value={data?.error_redirect_url}
                      name="error_redirect_url"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {(campaignProperties?.error_redirect_content || campaignProperties?.error_redirect_content === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Error Redirect Content</label>
                    <Input
                      multiline={true}
                      className={styles.textarea}
                      value={data?.error_redirect_content}
                      name="error_redirect_content"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {campaignProperties?.allow_multiple_registration && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Allow multiple registration</label>
                    <Input
                      toggle={true}
                      value={data?.allow_multiple_registration}
                      name="allow_multiple_registration"
                      disabled={!edit}
                      checked={!!data?.allow_multiple_registration}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {campaignProperties?.token_on_register && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Token on register</label>
                    <Input
                      toggle={true}
                      value={data?.token_on_register}
                      name="token_on_register"
                      disabled={!edit}
                      checked={!!data?.token_on_register}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {campaignProperties?.single_use_codes && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Single use codes</label>
                    <Input
                      toggle={true}
                      value={data?.single_use_codes}
                      name="single_use_codes"
                      disabled={!edit}
                      checked={!!data?.single_use_codes}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                )}

                {(campaignProperties?.probability_total_sum || campaignProperties?.probability_total_sum === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Probability total sum</label>
                    <Input
                      type="number"
                      className={styles.formInput}
                      value={data?.probability_total_sum}
                      name="probability_total_sum"
                      min={1}
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                      onKeyDown={(e) => ['-','+'].includes(e.key) && e.preventDefault()}
                    />
                  </div>
                )}

                {(campaignProperties?.max_rewards_per_consumer || campaignProperties?.max_rewards_per_consumer === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Maximum rewards per consumer</label>
                    <Input
                      type="number"
                      className={styles.formInput}
                      value={data?.max_rewards_per_consumer}
                      name="max_rewards_per_consumer"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                      onKeyDown={(e) => ['-','+'].includes(e.key) && e.preventDefault()}
                    />
                  </div>
                )}

                {(campaignProperties?.repeat_scan_max_value || campaignProperties?.repeat_scan_max_value === '') && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Maximum repeat scan</label>
                    <Input
                      type="number"
                      className={styles.formInput}
                      value={data?.repeat_scan_max_value}
                      name="repeat_scan_max_value"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                      onKeyDown={(e) => ['-','+'].includes(e.key) && e.preventDefault()}
                    />
                  </div>
                )}

                {campaignProperties?.reward_value_groups && (
                  <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Reward value groups</label>
                    <Input
                      className={styles.formInput}
                      value={data?.reward_value_groups}
                      name="reward_value_groups"
                      disabled={!edit}
                      onChange={(e) => handleInputChange(e)}
                      onKeyDown={(e) => ['-','+'].includes(e.key) && e.preventDefault()}
                    />
                  </div>
                )}
              </div>
            )}

            <Button
              submit
              disabled={userPermissions?.features?.redemption_extended_settings ?
                !dirty && data?.view_timezone === selectedTimezone && _.isEqual(formattedData, campaignProperties)
                : !dirty && data?.view_timezone === selectedTimezone}
              type="action"
              size="large"
              className={styles.submitButton}
            >
              Save Changes
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
