import React, { useEffect, useState } from 'react';

import TableBody from './components/TableBody/TableBody';
import TableHeader from './components/TableHeader/TableHeader';
import styles from './Table.module.scss';
import TablePagination from './components/TablePagination/TablePagination';
import classNames from 'classnames';

interface Props {
  id?: string,
  rows: React.ReactNode[][];
  headers: any[];
  sort?: any;
  page?: number;
  rowsPerPage?: number;
  totalRows?: number;
  onSort?: any;
  onFilter?: (field: string, value: any) => void;
  onPageSelected?: any;
  onRowsNumberSelected?: any;
  rowsNumbers?: any;
  filters?: any;
  resizableFields?: string[];
  disabled?: boolean;
}

export default function Table({
  id,
  rows,
  onPageSelected,
  totalRows,
  rowsPerPage,
  onRowsNumberSelected,
  rowsNumbers,
  headers,
  sort,
  filters,
  page,
  onSort,
  onFilter,
  resizableFields,
  disabled
}: Props): React.ReactElement {
  const getColumnsWidth = localStorage.getItem(`${id}-columns-width`);
  const [changedWidth, setChangedWidth] = useState({});

  const headerWidth = getColumnsWidth
    ? Object.assign({}, ...JSON.parse(getColumnsWidth).map((item: any) => {
      return {
        [item.field]: item.width
      }
    }))
    : Object.assign({}, ...headers.map((item: any) => {
      return {
        [item.field]: item.width
      }
    }));

  const newFormatWidths = Object.entries({ ...headerWidth, ...changedWidth }).map(item => {
    return {
      field: item[0],
      width: item[1]
    }
  })

  useEffect(() => {
    if (Object.keys(changedWidth)?.length) {
      localStorage.setItem(`${id}-columns-width`, JSON.stringify(newFormatWidths))
    }
  }, [changedWidth])

  useEffect(() => {
      createResizableTable()
  }, [])

  const createResizableColumn = (col: any, resizer: any) => {
    // Track the current position of mouse
    let x = 0;
    let w = 0;

    const findMinColWidth = headers?.find(item => item.field === col.id).width;

    const mouseDownHandler = (e: any) => {
      // Get the current mouse position
      x = e.clientX;

      // Calculate the current width of column
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
      resizer.classList.add('resizing');
    };

    const mouseMoveHandler = (e: any) => {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column
      const colWidth = `${w + dx}px`;

      // Resize until a minimum width
      const newColumnWidth = Math.max(parseInt(colWidth, 10), parseInt(findMinColWidth, 10));
      col.style.width = `${newColumnWidth}px`;
      setChangedWidth(prev => ({ ...prev, ...{ [col.id]: col.style.width } }))
    }

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      resizer.classList.remove('resizing');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };
    resizer.addEventListener('mousedown', mouseDownHandler);
  }

  const createResizableTable = () => {
    const table = document.getElementById(`${id}-resize-table`);
    const cols = table?.querySelectorAll('th');

    cols?.forEach((col: any) => {
      // Create a resizer element
      const resizer = document.createElement('div');
      resizer.classList.add(styles.resizer);


      // Add a resizer element to the column
      col?.appendChild(resizer);

      createResizableColumn(col, resizer);
    })
  }

  return (
    <div className={classNames(styles.root, 'table-root')}>
      <div className={styles.tableHolder}>
        <table cellSpacing={0} className={styles.table} id={`${id}-resize-table`}>
          <TableHeader
            id={id}
            onFilter={onFilter}
            sort={sort}
            headers={headers}
            onSort={onSort}
            filters={filters}
            disabled={disabled}
          />
          {rows?.length > 0 &&
            <TableBody
              id={id}
              rows={rows}
              headers={headers}
              resizableFields={resizableFields}
            />
          }
        </table>
        {!rows?.length &&
          <div className={styles.noEntriesBlock}>
            <div className={styles.stickyLabel}>No entries</div>
          </div>
        }
      </div>

      {totalRows !== undefined && rowsPerPage !== undefined && page !== undefined && (
        <TablePagination
          onPageSelected={onPageSelected}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          onRowsNumberSelected={onRowsNumberSelected}
          rowsNumbers={rowsNumbers}
          page={page}
        />
      )}
    </div>
  );
}
