import React, { useEffect } from 'react';
import truncate from 'lodash/truncate'
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import styles from './TableBody.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

interface Props {
    id?: string;
    rows: React.ReactNode[][];
    headers?: any[];
    resizableFields?: string[];
}


export default function TableBody({ rows, headers, id, resizableFields }: Props): React.ReactElement {
    const campaignId = useSelector<RootState, string>((state) => state.campaign.campaign.campaign_id);
    const rewardsTablePage = useSelector<RootState, number>((state) => state.campaignDetailsPage.rewardsTablePage);
    const shortcodesTablePage = useSelector<RootState, number>((state) => state.campaignDetailsPage.shortcodesTablePage);

    const getColumnsWidth = localStorage.getItem(`${id}-columns-width`);

    const handleCheckWidth = () => {
        resizableFields?.forEach(field => {
            const findWidth = getColumnsWidth
                ? JSON.parse(getColumnsWidth)?.find((item: any) => item.field === field)?.width
                : headers?.find((item: any) => item.field === field)?.width

            const findElements = document.querySelectorAll<HTMLElement>(`.${field}`)
            const actionElementWidth = document.querySelector<HTMLElement>('.actions')?.offsetWidth
            const shortcodeURLDiff = actionElementWidth && (parseInt(findWidth, 10) - actionElementWidth)

            Array.from(findElements).forEach(item => {
                item.classList.remove('truncate');
                !!item.parentElement && item.parentElement.classList.remove('tooltip')

                if (item.offsetWidth > (parseInt(findWidth, 10) - 5) ||
                    shortcodeURLDiff && (item.offsetWidth > shortcodeURLDiff)) {
                    item.classList.add('truncate');
                    item.style.width = findWidth;
                } else {
                    item.classList.remove('truncate');
                }

                if (field === 'notes' && item.classList.contains('truncate')) {
                    !!item.parentElement?.parentElement?.parentElement &&
                        item.parentElement?.parentElement?.parentElement.classList.add('tooltip');
                }
            })
        })
    }

    useEffect(() => {
        handleCheckWidth()
    }, [getColumnsWidth, campaignId, rewardsTablePage, shortcodesTablePage, rows])

    return (
        <tbody className={styles.root}>
            {rows?.map((cols, i) => (
                <tr className={styles.tableRow} key={i}>
                    {cols?.map((col: any, index: number) => {
                        return (
                            <td key={index} className={styles.tableCell}>
                                {col?.props?.className === 'notes' && col?.props?.children?.length > 60 ? (
                                    <Tooltip
                                        tooltip={col?.props?.children?.length > 200
                                            ? truncate(col?.props?.children, { 'length': 202 })
                                            : col}
                                        position={i === (rows?.length - 1) ? 'top' : 'bottom'}
                                    >
                                        {col}
                                    </Tooltip>
                                ) : (
                                    <>{col}</>
                                )}
                            </td>
                        )
                    })}
                </tr>
            ))}
        </tbody>
    );
}
