import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchOrganisations, fetchPermissions } from './authApi';
import { ORGANISATION_TYPES } from '../../app/constants/xelacoreConstants';
import cookie from 'js-cookie';
import { ORG_ID_COOKIE } from '../../app/constants/oauthConstants';
import cookies from 'js-cookie';
import config from '../../env-config';

const initialState = {
  organisations: [],
  orgId: null,
  authPermissions: {},
  userPermissions: {},
};

export const getOrganisations = createAsyncThunk('auth/getOrganisations', async () => {
  const response = await fetchOrganisations();
  return response.data;
});

export const getPermissions = createAsyncThunk('auth/getPermissions', async () => {
  const response = await fetchPermissions();
  return response.data;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setOrgId(state, action) {
      state.orgId = action.payload;
    },
    setOrganisations(state, action) {
      state.organisations = action.payload;
    },
    setAuthPermissions(state, action) {
      state.authPermissions = action.payload;
    },
    setUserPermissions(state, action) {
      state.userPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganisations.fulfilled, (state, action) => {
      state.organisations = action.payload.organisations;
      const organisationId = cookie.get(ORG_ID_COOKIE);
      const filteredOrgLicensors = action.payload.organisations
        .filter(({ type }) => type === ORGANISATION_TYPES.LICENSOR)
        .map((organisation) => organisation.organisation_id);
      if (!filteredOrgLicensors.includes(organisationId)) {
        state.orgId = null;
        cookies.remove(ORG_ID_COOKIE, { domain: config.appDomain, secure: true, sameSite: 'strict' });
      }
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.authPermissions = action.payload;
    });
  },
});

export const { setOrgId, setOrganisations, setAuthPermissions, setUserPermissions } = authSlice.actions;

export default authSlice.reducer;
