import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit';
import { Partner } from 'app/interfaces/partner';
import { replaceEmptyValuesWithNulls, trimAllValues } from 'app/utils/helpers';
import { showNotification } from 'state/ui/notifications/notificationsSlice';
import fileDownload from 'app/utils/fileDownload';
import {
    fetchCampaign,
    fetchPartners,
    fetchMetrics,
    patchPartners,
    patchReward,
    postChunkCodes,
    postPartners,
    postReward,
    fetchRewards,
    downloadMetricsCsv,
    fetchShortcodes,
    postShortcode,
    patchShortcode,
    downloadShortcode,
    fetchReward,
} from '../../api/campaignApi';
import {
    defaultLanguageData,
    metricsFiltersDefaultValues,
    rewardsConditionsDefaultValues,
    rewardsSettingsDefaultValues,
} from '../../constants/rewardConstants';
import { CampaignProperties, LanguageData } from '../../interfaces/campaignInterfaces';
import { cloneDeep } from 'lodash';
import { shortcodesDefaultValues } from 'constants/shortcodeConstants';
import { resetPartnersTableSort, setPartnersTablePage } from 'state/ui/campaignDetails/campaignDetailsPageSlice';

interface CampaignSlice {
    campaign: any;
    reward: any;
    metrics: any;
    shortcodes: any;
    isCampaignLoading: boolean;
    isMetricsLoading: boolean;
    isRewardsLoading: boolean;
    isRewardLoading: boolean;
    isShortcodesLoading: boolean;
    partners: Partner[];
    campaignProperties: CampaignProperties;
    isPartnersLoading: boolean;
    isRewardCreated: boolean;
    isShortcodeCreated: boolean;
    isCampaignNotFound: boolean;
    campaignPartners: {
        partnerErrors: any;
        partnerServerError: string | null;
    };
    metricsFilters: {
        from: string | null;
        to: string | null;
    };
    rewardId: string | null;
    tabTouched: boolean;
    rewardsSettings: {
        values: {
            reward_name: string;
            reward_probability: string;
            reward_value: number;
            partner_id: string;
            [key: string]: string | number;
        };
    };
    rewardsConditions: {
        values: {
            target_countries: string[];
            target_variant: string;
            daily_limit: string;
            start_at: string | null;
            end_at: string | null;
            [key: string]: string | string[] | null;
        };
    };
    rewardsLanguages: {
        values: { [key: string]: LanguageData };
        currentLanguage: string;
    };
    rewardVoucherCodes: {
        values: {
            codes: string[];
            win_max: number | string;
            quantity: number | string;
            [key: string]: string | string[] | number | boolean;
        };
    };
    shortcodeId: string | null;
    shortcodeData: {
        values: {
            short_code: string;
            redirect_url: string;
            ignore_campaign_filter: boolean;
            notes: string;
            [key: string]: string | null | number | boolean;
        };
    };
    shortcodeFormat: {
        format: string
    }
}

const initialState = {
    campaign: null,
    reward: null,
    isCampaignLoading: true,
    partners: [],
    campaignProperties: { view_timezone: '' },
    shortcodes: [],
    metrics: [],
    metricsFilters: metricsFiltersDefaultValues,
    isMetricsLoading: false,
    isPartnersLoading: false,
    isRewardsLoading: false,
    isRewardLoading: false,
    isShortcodesLoading: false,
    isCampaignNotFound: false,
    isRewardCreated: false,
    isShortcodeCreated: false,
    campaignPartners: {
        partnerErrors: {},
        partnerServerError: null,
    },
    rewardId: null,
    tabTouched: false,
    rewardsSettings: {
        values: rewardsSettingsDefaultValues,
    },
    rewardsConditions: {
        values: rewardsConditionsDefaultValues,
    },
    rewardsLanguages: { values: {}, currentLanguage: '' },
    rewardVoucherCodes: {
        isManual: false,
        values: {
            codes: [],
            win_max: 1,
            quantity: '',
            not_visible_in_email: false,
            not_visible_in_email_upload: false
        },
    },
    shortcodeId: null,
    shortcodeData: {
        values: shortcodesDefaultValues
    },
    shortcodeFormat: {
        format: ''
    }
} as CampaignSlice;

export const getCampaign = createAsyncThunk<any, any, any>(
    'campaigns/getCampaign',
    async (id: string, { rejectWithValue }) => {
        try {
            const {
                data: { data },
            } = await fetchCampaign(id);
            return data;
        } catch (err: any) {
            if (err.response.status === 404 || err.response.status === 401) {
                return rejectWithValue(null);
            }
        }
    }
);

export const getPartners = createAsyncThunk('campaigns/getPartners', async (id: string) => {
    const {
        data: {
            data: { partners },
        },
    } = await fetchPartners(id);
    return partners;
});

export const getMetrics = createAsyncThunk<any, any, { state: { campaign: CampaignSlice } }>(
    'campaigns/getMetrics',
    async (id: string, { getState }) => {
        const {
            campaign: { metricsFilters },
        } = getState();
        const {
            data: {
                data: { metrics },
            },
        } = await fetchMetrics(id, metricsFilters);
        return metrics;
    }
);

export const downloadMetricsCsvFunction = createAsyncThunk<any, any, { state: { campaign: CampaignSlice } }>(
    'campaigns/downloadMetricsCsvFunction',
    async (id: string, { getState }) => {
        const {
            campaign: { metricsFilters },
        } = getState();
        const data = await downloadMetricsCsv(id, metricsFilters);
        const dateNow = new Date();
        const fileName = `campaign-metrics-${dateNow.toISOString().replace(/.\d+Z$/g, '')}`;

        return fileDownload(data, `${fileName}.csv`);
    }
);

export const getShortcodes = createAsyncThunk('campaigns/getShortcodes', async (id: string) => {
    const {
        data: {
            data: { shortCodes },
        },
    } = await fetchShortcodes(id);
    return shortCodes;
});

export const downloadShortcodeFunction = createAsyncThunk<any, any, { state: { campaign: CampaignSlice } }>(
    'campaigns/downloadShortcodeFunction',
    async (findCurrentShortcodeURL: string, { getState }) => {
        const {
            campaign: { shortcodes, shortcodeId, shortcodeFormat },
        } = getState();
        const fileName = shortcodes?.find((item: any) => item.short_code_id === shortcodeId)?.short_code;

        const data = await downloadShortcode(findCurrentShortcodeURL, shortcodeFormat, fileName);
        return fileDownload(data, `${fileName}.${shortcodeFormat.format}`);
    }
);

export const getRewards = createAsyncThunk('campaigns/getRewards', async (id: string) => {
    const {
        data: {
            data: { rewards },
        },
    } = await fetchRewards(id);
    return rewards;
});

export const getReward = createAsyncThunk<any, any>(
    'campaigns/getReward',
    async (data) => {
        const { id, reward_id } = data
        const {
            data: {
                data: { reward },
            },
        } = await fetchReward(id, reward_id);
        return reward;
    });

export const sendPartnersData = createAsyncThunk<any, Partner, { state: { campaign: any } }>(
    'campaigns/sendPartnersData',
    async (partner, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                campaign: {
                    campaign: { campaign_id },
                },
            } = getState();
            let newData;
            if (!partner.isNew) {
                newData = await patchPartners(campaign_id, [partner]);
                dispatch(showNotification('Partner edited successfully!'));
            } else {
                const newPartner = { name: partner.name, support_email: partner.support_email };
                newData = await postPartners(campaign_id, [newPartner]);
                dispatch(showNotification('Partner added successfully!'));
                dispatch(resetPartnersTableSort());
                dispatch(setPartnersTablePage(0));
            }
            const {
                data: {
                    data: { partners },
                },
            } = newData;
            return partners;
        } catch (err: any) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(err.response.error.detail);
        }
    }
);

export const sendRewardData = createAsyncThunk<any, void, { state: { campaign: any } }>(
    'campaigns/sendRewardsData',
    async (_data, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                campaign: {
                    campaign: { campaign_id },
                    rewardsSettings: { values: rewardSettings },
                    rewardsConditions: { values: rewardConditions },
                    rewardsLanguages: { values: rewardsLanguages },
                    rewardId,
                },
            } = getState();
            const normalizedRewardSettings = replaceEmptyValuesWithNulls(trimAllValues(rewardSettings));
            const normalizedRewardConditions = replaceEmptyValuesWithNulls(trimAllValues(rewardConditions));
            const reward = {
                ...normalizedRewardSettings,
                ...normalizedRewardConditions,
                reward_attributes: { ...trimAllValues(rewardsLanguages, 'reward_languages') },
            };
            if (rewardId) {
                const response = await patchReward(campaign_id, rewardId, reward);
                dispatch(setCampaignRewards(response.data.data.rewards));
                dispatch(showNotification('Reward edited successfully!'));
                return null;
            } else {
                reward.end_at = null;
                reward.is_enabled = false;
                const response = await postReward(campaign_id, reward);
                const newReward = response.data.data.rewards[0];
                dispatch(showNotification(`Reward ${newReward.reward_name} was successfully created!`));
                return newReward;
            }
        } catch (err: any) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(err.response.error.detail);
        }
    }
);

export const duplicateRewardData = createAsyncThunk<any, void, { state: { campaign: any } }>(
    'campaigns/sendRewardsData',
    async (_data, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                campaign: {
                    campaign: { campaign_id },
                    rewardsSettings: { values: rewardSettings },
                    rewardsConditions: { values: rewardConditions },
                    rewardsLanguages: { values: rewardsLanguages },
                },
            } = getState();
            const normalizedRewardSettings = replaceEmptyValuesWithNulls(trimAllValues(rewardSettings));
            const normalizedRewardConditions = replaceEmptyValuesWithNulls(trimAllValues(rewardConditions));
            const reward = {
                ...normalizedRewardSettings,
                ...normalizedRewardConditions,
                reward_attributes: { ...trimAllValues(rewardsLanguages, 'reward_languages') },
            };
            const response = await postReward(campaign_id, reward);
            const newReward = response.data.data.rewards[0];
            dispatch(showNotification(`Reward ${newReward.reward_name} was successfully created!`));
            return newReward;
        } catch (err: any) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(err.response.error.detail);
        }
    }
);

export const sendCodesData = createAsyncThunk<any, { fileUploadCodes: boolean }, { state: { campaign: any } }>(
    'campaigns/sendRewardsData',
    async ({ fileUploadCodes }, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                campaign: {
                    campaign: { campaign_id, rewards },
                    rewardVoucherCodes: { values },
                    rewardId,
                },
            } = getState();
            const tempValues = cloneDeep(values);
            tempValues.not_visible_in_email = !fileUploadCodes ? tempValues.not_visible_in_email : tempValues.not_visible_in_email_upload;
            const trimmedValues = trimAllValues(tempValues)
            await postChunkCodes(campaign_id, rewardId, trimmedValues, 100);

            const newRewards = rewards.map((item: any) =>
                item.reward_id === rewardId
                    ? { ...item, win_max: values.win_max }
                    : item);
            dispatch(setCampaignRewards(newRewards));
            dispatch(showNotification('Reward codes successfully added!'));
        } catch (err: any) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(err.response.error.detail);
        }
    }
);

export const sendShortcodeData = createAsyncThunk<any, void, { state: { campaign: any } }>(
    'campaigns/sendShortcodesData',
    async (_data, { rejectWithValue, getState, dispatch }) => {
        try {
            const {
                campaign: {
                    campaign: { campaign_id },
                    shortcodeData: { values: shortcodeData },
                    shortcodes,
                    shortcodeId,
                },
            } = getState();
            const normalizedShortcodeValues = trimAllValues(shortcodeData);
            const shortcode = {
                ...normalizedShortcodeValues
            };

            if (shortcodeId) {
                const response = await patchShortcode(campaign_id, shortcodeId, shortcode);
                const newShortcode = response.data.data.shortcode;
                const newShortcodes = shortcodes.map((item: any) =>
                    item.short_code_id === newShortcode.short_code_id
                        ? newShortcode
                        : item);
                dispatch(setCampaignShortcodes(newShortcodes));
                dispatch(showNotification('Shortcode edited successfully!'));
                return null;
            } else {
                const response = await postShortcode(campaign_id, shortcode);
                const newShortcode = response.data.data.shortCode;
                dispatch(showNotification(`Shortcode ${newShortcode.short_code} was successfully created!`));
                return newShortcode;
            }
        } catch (err: any) {
            const error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(err.response.data.error.title);
        }
    }
);


export const authSlice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        addPartner: (state) => {
            state.campaign.partners.unshift({ name: '', support_email: '', partner_id: nanoid(), isNew: true });
        },
        editPartnerEmail: (state, { payload: { partner_id, support_email } }) => {
            const partner = state.campaign.partners.find((p: Partner) => p.partner_id === partner_id);
            if (partner) {
                partner.support_email = support_email;
            }
        },
        editPartnerName: (state, { payload: { partner_id, name } }) => {
            const partner = state.campaign.partners.find((p: Partner) => p.partner_id === partner_id);
            if (partner) {
                partner.name = name;
            }
        },
        setPartnerError: (state, action) => {
            state.campaignPartners.partnerErrors = action.payload;
        },
        clearPartnerError: (state, action) => {
            state.campaignPartners.partnerErrors[action.payload.id] = null;
        },
        setRewardSettingsFieldValue: (state, action: { payload: { field: string; value: string | number } }) => {
            state.rewardsSettings.values[action.payload.field] = action.payload.value;
        },
        setRewardConditionsFieldValue: (state, action: {
            payload: { field: string; value: string | string[] | null }
        }) => {
            state.rewardsConditions.values[action.payload.field] = action.payload.value;
        },
        addRewardsLanguage: (state, action) => {
            const prefillData = { ...defaultLanguageData };
            prefillData.reward_image_url = action.payload.reward_image_url
            state.rewardsLanguages.values = {
                ...state.rewardsLanguages.values,
                [action.payload.newLanguage]: { ...prefillData },
            };
            state.rewardsLanguages.currentLanguage = action.payload.newLanguage;
        },
        removeRewardsLanguage: (state, action) => {
            delete state.rewardsLanguages.values[action.payload];
        },
        setRewardsLanguageData: (
            state,
            { payload: { language, field, value } }: { payload: { language: string; field: string; value: string } }
        ) => {
            state.rewardsLanguages.values[language][field] = value;
        },
        setRewardsLanguageCurrentLanguage: (state, action) => {
            state.rewardsLanguages.currentLanguage = action.payload;
        },
        setRewardsVoucherCodesFieldValue: (state, action: {
            payload: { field: string; value: string | string[] | number | boolean }
        }) => {
            state.rewardVoucherCodes.values[action.payload.field] = action.payload.value;
        },
        setIsRewardCreated: (state, action) => {
            state.isRewardCreated = action.payload;
        },
        setRewardFormData: (state, action) => {
            state.rewardId = action.payload;
            if (action.payload) {
                const rewardValues = state.campaign?.rewards?.find((r: any) => r.reward_id === action.payload) || state.reward;
                state.rewardsSettings = {
                    values: {
                        reward_name: rewardValues.reward_name,
                        reward_probability: rewardValues.reward_probability,
                        reward_value: rewardValues.reward_value,
                        partner_id: rewardValues.partner_id,
                    },
                };
                state.rewardsConditions = {
                    values: {
                        target_variant: rewardValues.target_variant,
                        daily_limit: rewardValues.daily_limit,
                        target_countries: rewardValues.target_countries,
                        start_at: rewardValues.start_at,
                        end_at: rewardValues.end_at,
                    },
                };
                state.rewardsLanguages = {
                    values: { ...rewardValues.reward_attributes },
                    currentLanguage:
                        state.rewardsLanguages.currentLanguage ||
                        (rewardValues.reward_attributes
                            ? rewardValues.reward_attributes['en']
                                ? 'en'
                                : Object.keys(rewardValues.reward_attributes)[0]
                            : ''),
                };
            } else {
                state.rewardsSettings = {
                    values: rewardsSettingsDefaultValues,
                };
                state.rewardsConditions = {
                    values: rewardsConditionsDefaultValues,
                };
            }
        },
        resetRewardFormData: (state) => {
            state.rewardId = null;
            state.tabTouched = false;
            state.rewardsSettings = {
                values: rewardsSettingsDefaultValues,
            };
            state.rewardsConditions = {
                values: rewardsConditionsDefaultValues,
            };
            state.rewardsLanguages = { values: {}, currentLanguage: '' };
            state.rewardVoucherCodes = {
                values: {
                    codes: [],
                    win_max: 1,
                    quantity: '',
                    not_visible_in_email: false,
                    not_visible_in_email_upload: false
                },
            };
        },
        setResetReward: (state) => {
            state.reward = null;
        },
        setCampaignRewards: (state, action) => {
            state.campaign.rewards = action.payload;
        },
        setMetricsDateFilter: (state, { payload: { from, to } }) => {
            state.metricsFilters.from = from === undefined ? state.metricsFilters.from : from;
            state.metricsFilters.to = to === undefined ? state.metricsFilters.to : to;
        },
        resetMetricsDateFilter: (state) => {
            state.metricsFilters = metricsFiltersDefaultValues;
        },
        setTabTouched: (state, action) => {
            state.tabTouched = action.payload
        },
        setCampaignShortcodes: (state, action) => {
            state.shortcodes = action.payload;
        },
        setIsShortcodeCreated: (state, action) => {
            state.isShortcodeCreated = action.payload;
        },
        setShortcodeFieldValue: (state, action: {
            payload: { field: string; value: string | boolean }
        }) => {
            state.shortcodeData.values[action.payload.field] = action.payload.value;
        },
        setShortcodeId: (state, action) => {
            state.shortcodeId = action.payload;
        },
        resetShortcodeFormData: (state) => {
            state.shortcodeId = '';
            state.shortcodeData = {
                values: shortcodesDefaultValues,
            };
        },
        setShortcodeDownload: (state, action) => {
            state.shortcodeFormat = action.payload
        },
        setResetVoucherCodes: (state) => {
            state.rewardVoucherCodes = {
                values: {
                    codes: [],
                    win_max: '',
                    quantity: '',
                    not_visible_in_email: false,
                    not_visible_in_email_upload: false
                },
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCampaign.pending, (state) => {
            state.isCampaignLoading = true;
            state.isCampaignNotFound = false;
        });
        builder.addCase(getCampaign.rejected, (state) => {
            state.isCampaignNotFound = true;
        });
        builder.addCase(getCampaign.fulfilled, (state, action) => {
            state.campaign = action.payload;
            state.campaignProperties = action.payload.properties;
            state.isCampaignLoading = false;
        });
        builder.addCase(getPartners.pending, (state) => {
            state.isPartnersLoading = true;
        });
        builder.addCase(getPartners.fulfilled, (state, action) => {
            state.campaign.partners = action.payload;
            state.isPartnersLoading = false;
        });
        builder.addCase(sendPartnersData.pending, (state) => {
            state.isPartnersLoading = true;
        });
        builder.addCase(sendPartnersData.fulfilled, (state, action) => {
            state.isPartnersLoading = false;
            state.partners = action.payload;
            state.campaign.partners = action.payload;
        });
        builder.addCase(sendPartnersData.rejected, (state, action: any) => {
            state.campaignPartners.partnerServerError = action.payload;
            state.isPartnersLoading = false;
        });
        builder.addCase(sendRewardData.pending, (state) => {
            state.isCampaignLoading = true;
        });
        builder.addCase(sendRewardData.rejected, (state) => {
            state.isCampaignLoading = false;
        });
        builder.addCase(sendRewardData.fulfilled, (state, action) => {
            state.isCampaignLoading = false;
            state.isRewardCreated = !!action.payload;
            if (action.payload) {
                state.campaign.rewards.push(action.payload);
                state.rewardId = action.payload.reward_id;
            }
        });
        builder.addCase(getMetrics.pending, (state) => {
            state.isMetricsLoading = true;
        });
        builder.addCase(getMetrics.fulfilled, (state, action) => {
            state.isMetricsLoading = false;
            state.metrics = action.payload;
        });
        builder.addCase(getRewards.pending, (state) => {
            state.isRewardsLoading = true;
        });
        builder.addCase(getRewards.fulfilled, (state, action) => {
            state.isRewardsLoading = false;
            state.campaign.rewards = action.payload;
        });
        builder.addCase(getReward.pending, (state) => {
            state.isRewardLoading = true;
            state.isCampaignLoading = true;
        });
        builder.addCase(getReward.fulfilled, (state, action) => {
            state.isRewardLoading = false;
            state.isCampaignLoading = false;
            state.reward = action.payload;
        });
        builder.addCase(getShortcodes.pending, (state) => {
            state.isShortcodesLoading = true;
        });
        builder.addCase(getShortcodes.fulfilled, (state, action) => {
            state.isShortcodesLoading = false;
            state.shortcodes = action.payload;
        });
        builder.addCase(sendShortcodeData.pending, (state) => {
            state.isShortcodesLoading = true;
        });
        builder.addCase(sendShortcodeData.rejected, (state) => {
            state.isShortcodesLoading = false;
        });
        builder.addCase(sendShortcodeData.fulfilled, (state, action) => {
            state.isShortcodesLoading = false;
            state.isShortcodeCreated = !!action.payload;
            if (action.payload) {
                state.shortcodes.push(action.payload);
                state.shortcodeId = action.payload.short_code_id;
            }
        });
    },
});

export const {
    addPartner,
    editPartnerName,
    editPartnerEmail,
    setPartnerError,
    clearPartnerError,
    setRewardFormData,
    resetRewardFormData,
    setIsRewardCreated,
    setRewardSettingsFieldValue,
    setRewardConditionsFieldValue,
    setRewardsLanguageData,
    setRewardsLanguageCurrentLanguage,
    setRewardsVoucherCodesFieldValue,
    addRewardsLanguage,
    removeRewardsLanguage,
    setCampaignRewards,
    setMetricsDateFilter,
    resetMetricsDateFilter,
    setTabTouched,
    setCampaignShortcodes,
    setIsShortcodeCreated,
    setShortcodeFieldValue,
    setShortcodeId,
    resetShortcodeFormData,
    setShortcodeDownload,
    setResetReward,
    setResetVoucherCodes
} = authSlice.actions;

export default authSlice.reducer;
