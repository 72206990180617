import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, forEach } from 'lodash';
import { AppDispatch, RootState } from 'app/store';
import { trimAllValues } from 'app/utils/helpers';
import Skeleton from '../../../../app/components/Skeleton/Skeleton';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import {
    sendCodesData,
    setIsRewardCreated,
    setResetVoucherCodes,
    setRewardsVoucherCodesFieldValue
} from '../../../../state/campaign/campaignSlice';
import styles from './VoucherCodesManualInput.module.scss';
import { Formik } from 'formik';

export default function VoucherCodesManualInput(): React.ReactElement {
    const useAppDispatch = () => useDispatch<AppDispatch>();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const values = useSelector<RootState, { [key: string]: any }>(
        (state) => state.campaign.rewardVoucherCodes.values
    );
    const rewardsValues = useSelector<RootState, { [key: string]: string | number }>(
        (state) => state.campaign.rewardsSettings.values
    );
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;
    const [preservedData, setPreservedData] = useState(values);

    useEffect(() => {
        setPreservedData(trimAllValues(values))
    }, [values])

    useEffect(() => {
        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
    }, []);

    const preserveCampaign = (key: string, value: string[] | number | boolean) => {
        const newData = cloneDeep(preservedData);
        newData[key] = value;
        setPreservedData(newData);
    }

    const voucherCodeValue = (preservedData.codes as string[])[0] || '';

    const submitData = () => {
        forEach(preservedData, (value: any, key) => {
            if (value || (typeof value === 'boolean' && value === false)) {
                dispatch(setRewardsVoucherCodesFieldValue({
                    field: key,
                    value
                }))
            }
        })
        dispatch(sendCodesData({ fileUploadCodes: false })).then(() => {
            dispatch(setResetVoucherCodes());
        })
    }

    return (
        <Skeleton isLoading={isCampaignLoading}>
            <Formik
                initialValues={values}
                enableReinitialize={true}
                validate={() => {
                    const errors: any = {};
                    if (!voucherCodeValue) {
                        errors.voucherCodeValue = 'Voucher code cannot be empty';
                    }
                    if (!preservedData.win_max) {
                        errors.win_max = 'Times voucher code cannot be empty';
                    }
                    return errors;
                }}
                onSubmit={submitData}
            >
                {({ errors, handleChange, handleSubmit }) => (
                    <form
                        className={styles.root}
                        onSubmit={handleSubmit}
                    >
                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>
                                Reward Name
                            </label>
                            <div className={styles.textInput}>
                                {rewardsValues.reward_name}
                            </div>
                        </div>

                        <div className={styles.form}>
                            <div className={styles.inputHolderGrow}>
                                <p className={styles.label}>Voucher code</p>
                                <Input
                                    value={voucherCodeValue}
                                    onChange={(e) => {
                                        handleChange(e);
                                        preserveCampaign('codes', [e.target.value])
                                    }}
                                    className={styles.bigInput}
                                    error={!voucherCodeValue ? (errors.voucherCodeValue as string) : null}
                                />
                            </div>
                            <div className={styles.inputHolderGrow}>
                                <p className={styles.label}>Times voucher code can be used</p>
                                <Input
                                    value={preservedData?.win_max}
                                    onChange={(e) => preserveCampaign('win_max', e.target.value)}
                                    onKeyDown={(e) => ['-', '+'].includes(e.key) && e.preventDefault()}
                                    min={1}
                                    max={1000000000}
                                    type="number"
                                    className={styles.smallInput}
                                    error={!preservedData.win_max ? (errors.win_max as string) : null}
                                />
                            </div>
                        </div>

                        <div className={styles.checkboxInput}>
                            <label>
                                <input
                                    type="checkbox"
                                    value={preservedData?.not_visible_in_email}
                                    checked={!!preservedData.not_visible_in_email}
                                    onChange={(e) => {
                                        preserveCampaign('not_visible_in_email', e.target.checked);
                                    }}
                                />
                                <p className={styles.label}>Not visible in email</p>
                            </label>
                        </div>

                        <Button
                            submit
                            size="large"
                            type="action"
                            className={styles.submit}
                        >
                            Add code
                        </Button>
                    </form>
                )}
            </Formik>
        </Skeleton>
    );
}
