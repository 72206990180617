import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Skeleton from 'app/components/Skeleton/Skeleton';
import { resetMetricsDateFilter, setTabTouched } from '../../../../state/campaign/campaignSlice';
import Tab from 'components/Tabs/Tab';
import Tabs from 'components/Tabs/Tabs';
import CampaignPartners from '../../../Campaign/components/CampaignPartners/CampaignPartners';
import CampaignMetricsTable from '../CampaignMetricsTable/CampaignMetricsTable';
import CampaignRewards from '../CampaignRewards/CampaignRewards';
import CampaignShortcodesTable from '../CampaignShortcodes/CampaignShortcodesTable';
import styles from './CampaignDetailsContent.module.scss';
import EditCampaign from 'pages/CampaignManager/components/EditCampaign/EditCampaign';

export default function CampaignDetailsContent(): React.ReactElement {
    const dispatch = useDispatch();

    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const isEditLoading = useSelector<RootState, boolean>((state) => state.campaigns.isEditLoading);

    const [tabIndex, setTabIndex] = useState(2);

    useEffect(() => {
        return () => {
            dispatch(resetMetricsDateFilter());
        }
    }, [])

    return (
        <Tabs
            selectedIndex={tabIndex}
            onSelect={(i) => {
                dispatch(setTabTouched(true));
                setTabIndex(i);
            }}>
            <Tab name="Settings">
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isEditLoading}>
                        <EditCampaign />
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Partners">
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignPartners />}
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Rewards">
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignRewards />}
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Shortcodes">
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignShortcodesTable />}
                    </Skeleton>
                </div>
            </Tab>
            <Tab name="Metrics">
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignMetricsTable />}
                    </Skeleton>
                </div>
            </Tab>
        </Tabs>
    );
}
