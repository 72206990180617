import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { Formik } from 'formik';
import Skeleton from 'app/components/Skeleton/Skeleton';
import { Partner } from 'app/interfaces/partner';
import { RootState } from 'app/store';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import { ReactComponent as CheckIcon } from '../../../../static/icons/checkmark.svg';
import {
    sendRewardData,
    setIsRewardCreated,
    setRewardSettingsFieldValue,
} from '../../../../state/campaign/campaignSlice';
import { rewardTypes } from 'constants/rewardConstants';
import styles from './RewardSettings.module.scss';
import { forEach } from 'lodash';

export default function RewardSettings(): React.ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();

    const partners = useSelector<RootState, Partner[]>((state) => orderBy((state.campaign.campaign || {}).partners, [partner => partner.name.toLowerCase()], ['asc']));
    const rewards = useSelector<RootState, any>((state) => state.campaign.campaign?.rewards);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const values = useSelector<RootState, { [key: string]: string | number }>((state) => state.campaign.rewardsSettings.values);

    const [warning, setWarning] = useState('');
    const [errorRewardName, setErrorRewardName] = useState('');
    const [data, setData] = useState(values);

    const findSameRewardName = rewardId
        ? rewards?.find((item: any) => item.reward_id !== rewardId && item.reward_name === data.reward_name)
        : rewards?.find((item: any) => item.reward_name === data.reward_name)

    const rewardPattern = new RegExp('^[a-zA-Z_][a-zA-Z0-9\\s\\_]*$');
    const rewardNameCheck = rewardPattern.test(data.reward_name as string);

    useEffect(() => {
        setData(values)
    }, [rewardId, values])

    useEffect(() => {
        if (data.reward_name && !rewardNameCheck) {
            setErrorRewardName('Please enter a valid reward name')
        } else {
            setErrorRewardName('')
        }
    }, [data.reward_name, rewardNameCheck])

    useEffect(() => {
        if (findSameRewardName) {
            setWarning('Reward with this name already exists')
        } else {
            setWarning('')
        }
    }, [findSameRewardName])

    useEffect(() => {
        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
    }, [isRewardCreated, rewardId]);

    const dispatchValueChange = (name: string, value: string | number) => {
        dispatch(
            setRewardSettingsFieldValue({
                field: name,
                value,
            })
        );
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setData(prev => ({ ...prev, [name]: value }));
    }

    const submitData = () => {
        forEach(data, (value: string | number, key) => {
            dispatchValueChange(key, value);
        })

        dispatch(sendRewardData());
    }

    return (
        <Skeleton isLoading={isCampaignLoading}>
            <Formik
                initialValues={values}
                enableReinitialize={true}
                validate={(values) => {
                    const errors: any = {};
                    if (!values.reward_name) {
                        errors.reward_name = 'Reward name cannot be empty';
                    }
                    if (values.reward_value === null || values.reward_value === undefined) {
                        errors.reward_value = 'Please select reward type';
                    }
                    if (!values.reward_probability) {
                        errors.reward_probability = 'Win probability cannot be empty';
                    }
                    if (!values.partner_id) {
                        errors.partner_id = 'Please select a Partner';
                    }
                    return errors;
                }}
                onSubmit={submitData}
            >
                {({ values, errors, handleSubmit, handleChange, setFieldValue, touched }) => (
                    <form
                        className={styles.root}
                        onSubmit={handleSubmit}
                    >
                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>
                                Reward Name<span className={styles.red}>*</span>
                            </label>
                            <Input
                                className={styles.formInput}
                                value={data.reward_name}
                                name="reward_name"
                                onChange={(e) => {
                                    handleChange(e);
                                    handleInputChange(e)
                                }}
                                error={touched.reward_name && errors.reward_name ? (errors.reward_name as string) : null}
                            />
                            {warning && <div className={styles.warning}>{warning}</div>}
                            {errorRewardName && <div className={styles.error}>{errorRewardName}</div>}
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>
                                Reward Type<span className={styles.red}>*</span>
                            </label>
                            <Dropdown
                                options={rewardTypes}
                                className={styles.formInput}
                                value={{
                                    value: values.reward_value,
                                    label: rewardTypes.find((rt) => rt.value === values.reward_value)?.label || '',
                                }}
                                name="reward_value"
                                onChange={async ({ value }) => {
                                    await setFieldValue('reward_value', value);
                                    setData(prev => ({ ...prev, reward_value: value }))
                                }}
                                error={touched.reward_value && errors.reward_value ? (errors.reward_value as string) : null}
                            />
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>
                                Win Probability<span className={styles.red}>*</span>
                            </label>
                            <div className={styles.inputHolder}>
                                <Input
                                    className={styles.formInput}
                                    value={data.reward_probability}
                                    name="reward_probability"
                                    type="number"
                                    min={0}
                                    max={1000000000}
                                    onChange={(e) => {
                                        handleChange(e);
                                        handleInputChange(e)
                                    }}
                                    error={touched.reward_probability && errors.reward_probability ? (errors.reward_probability as string) : null}
                                />
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>
                                Link to Partner<span className={styles.red}>*</span>
                            </label>
                            <Dropdown
                                options={partners ? partners.map((p) => ({ value: p.partner_id, label: p.name })) : []}
                                className={styles.formInput}
                                value={{
                                    value: values.partner_id,
                                    label: partners ? partners.find((p) => p.partner_id === values.partner_id)?.name || '' : '',
                                }}
                                name="partner_id"
                                onChange={async ({ value }) => {
                                    await setFieldValue('partner_id', value);
                                    setData(prev => ({ ...prev, partner_id: value }))
                                }}
                                error={touched.partner_id && errors.partner_id ? (errors.partner_id as string) : null}
                            />
                        </div>

                        <div className={styles.inlineActions}>
                            <Button submit type="action"
                                size="large"
                                className={styles.submitButton}
                                iconLeft={<CheckIcon height={20} />}
                            >
                                Confirm Changes
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </Skeleton>
    );
}
